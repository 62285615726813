import React from "react";
import { Input, FormField, Card, Button, Box, Loader } from '@wix/design-system';

const LoaderPro = ({ onChange, email, proceed, isLoading }: any) => {

    const status = () => {
        if (isLoading) {
            return 'loading'
        }

        if (!email) {
            return "error"
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            return "error"
        }
        return undefined
    }

    const statusMessage = () => {
        if (isLoading) {
            return undefined
        }
        if (!email) {
            return "Email is required"
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            return "Invalid email address"
        }
        return undefined
    }

    return <Card>
        <Card.Content>
            <Box direction='vertical' gap="SP3">
                <FormField status={status()} statusMessage={statusMessage()} label="Enter your email address to setup billing card:">
                    <Input disabled={isLoading} size="large" border="round" type='email' onChange={(v) => {
                        onChange(v.target.value)
                    }}></Input>
                </FormField>
                <Button disabled={!!status() || isLoading} onClick={() => { proceed(true) }}>{isLoading ? <Loader size="tiny"></Loader> : "Continue"}</Button>
            </Box>
        </Card.Content>
    </Card>
};

export default LoaderPro;