import {
  WixDesignSystemProvider,
  Image,
  Page,
  EmptyState,
  CircularProgressBar,
  Cell,
  Layout,
  TextButton,
  Text,
  Divider,
  Box
} from '@wix/design-system';
import "@wix/design-system/styles.global.css";
import * as Icons from '@wix/wix-ui-icons-common';
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { ReactTagManager } from 'react-gtm-ts';
import Loader from './Component/Loader'
import Logo from './certifiedcode-logo.svg'
import { createElement, loadAirwallex } from 'airwallex-payment-elements';
import { createPaymentIntent } from './utils';

const tagManagerArgs = {
  code: 'GTM-WZQPMC7'
}

function App() {
  ReactTagManager.init(tagManagerArgs)
  const [email, setEmail] = useState(''); // Example: set email state
  const [elementShow, setElementShow] = useState(false); // Example: set element show state
  const [paymentStatus, setPaymentStatus] = useState(''); // Example: set payment status
  const [isLoading, setIsLoading] = useState(false); // Example: set loading state

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email');
    // check if valid by regex
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    if (email && emailRegex.test(email) && isLoading === false && paymentStatus === '' && elementShow === false) {
      setEmail(email)
      loadPayment()
      setIsLoading(true)
    }
  }, [])

  const loadPayment = () => {
    const loadFullFeaturedCard = async () => {
      try {
        // STEP #2: Initialize Airwallex on mount with the appropriate production environment and other configurations
        await loadAirwallex({
          env: 'prod', // Can choose other production environments, 'staging | 'demo' | 'prod'
          origin: window.location.origin, // Setup your event target to receive the browser events message
          // For more detailed documentation at https://github.com/airwallex/airwallex-payment-demo/tree/master/docs#loadAirwallex
        });
        // STEP #3: Create payment intent
        const intent = await createPaymentIntent(email);
        // STEP #4: Create the drop-in element
        const element = createElement('fullFeaturedCard', {
          client_secret: intent.client_secret,
          style: {
            // the 3ds popup window dimension
            popupWidth: 400,
            popupHeight: 549,
          },
          customer_id: intent.customer_id,
          recurringOptions: {
            next_triggered_by: "merchant",
            merchant_trigger_reason: "unscheduled",
            currency: "USD"
          },
          mode: "recurring"
        });
        // STEP #5: Mount the element to the empty container created previously
        element?.mount('fullFeaturedCard'); // This 'fullFeaturedCard' id MUST MATCH the id on your empty container created in Step 4
        setElementShow(true)
      } catch (error) {
        console.error(error);
      }
    };
    loadFullFeaturedCard();
    // STEP #6: Add an event listener to handle events when the element is mounted
    const onReady = (event: CustomEvent): void => {
      /**
       * Handle event on element mount
       */
      setElementShow(true);
      console.log(`Element is ready ${JSON.stringify(event.detail)}`);
      setPaymentStatus('pending');
    };

    // STEP #7: Add an event listener to handle events when the payment is successful
    const onSuccess = (event: CustomEvent): void => {
      /**
       * Handle event on success
       */
      console.log(`Confirm success with ${JSON.stringify(event.detail)}`);
      setPaymentStatus('success');
    };

    // STEP #8: Add an event listener to handle events when the payment has failed
    const onError = (event: CustomEvent) => {
      /**
       * Handle event on error
       */
      const { error } = event.detail;
      console.error('There is an error', error);
      setPaymentStatus('error');
    };
    const domElement = document.getElementById('fullFeaturedCard');
    domElement?.addEventListener('onReady', onReady as EventListener);
    domElement?.addEventListener('onSuccess', onSuccess as EventListener);
    domElement?.addEventListener('onError', onError as EventListener);
    return () => {
      domElement?.removeEventListener('onReady', onReady as EventListener);
      domElement?.removeEventListener('onSuccess', onSuccess as EventListener);
      domElement?.removeEventListener('onError', onError as EventListener);
    };
  };

  // Example: Custom styling for the wechat container, can be placed in css
  const containerStyle = {
    // width: '540px',
    // margin: '48px auto',
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height="100vh">
        <Page.Header title={<Image src={Logo} transparent={true} width={"200px"} borderRadius={"0px"}></Image>} subtitle="Custom B2B solutions billing goes here." />
        <Page.Content>
          {(!elementShow) ? <Loader
            email={email}
            onChange={(v: string) => {
              setEmail(v)
            }}
            isLoading={isLoading}
            proceed={(v: boolean) => {
              if (v) {
                loadPayment()
                setIsLoading(true)
              }
            }}
          ></Loader> : <></>}
          <Layout>
            <Cell span={12}>
              <div
                id="fullFeaturedCard"
                style={{
                  ...containerStyle, // Example: container styling can be moved to css
                  display: (elementShow && paymentStatus === 'pending') ? 'block' : 'none', // Example: only show element when mounted
                }}
              />
            </Cell>
          </Layout>
          {paymentStatus === 'success' ? <EmptyState
            theme="page-no-border"
            image={
              <CircularProgressBar skin="success" size="medium" value={100} />
            }
            title="Payment method verified!"
            subtitle="Your payment method has been verified. Close this window to continue."
          >
          </EmptyState> : <></>}
          {paymentStatus === 'error' ? <EmptyState
            theme="page-no-border"
            image={
              <CircularProgressBar error size="medium" value={100} />
            }
            title="We are unable to verify your payment method."
            subtitle="Please try again."
          >
            <TextButton prefixIcon={<Icons.Refresh />} onClick={() => { }}>Try Again</TextButton>
          </EmptyState> : <></>}
          <Box height="20px"></Box>
          <Text skin="disabled" size="tiny">
            Contact your account manager if you have any questions.
          </Text>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
